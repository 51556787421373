<template>
  <div class="all">
    <Nav></Nav>
    <div class="about">
      <div class="announce-catitle">
        <div class="new-text">
          <div class="title"></div>
          <div class="text">{{ announceTitle }}</div>
        </div>
        <div class="line"></div>
      </div>
      <div class="announce-list">
        <div class="announce-con" v-for="k in announceList" :key="k.id">
          <div class="annoce-title">
            <div class="title">{{ k.title }}</div>
          </div>
          <div class="annoce-nav">
            <div class="img" :style="{ backgroundImage: `url(` + k.imgurl + `)` }"></div>
            <div class="text">
              <div class="detail-text" v-html="k.text"></div>
              <div class="more" @click="onClick(k.id)">活动详情>>></div>
              <div class="time">{{ k.startTime }}--{{ k.endTime }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>
<script>
// @ is an alias to /src
import RD from '@/api/RD'
import Nav from '@/components/nav.vue'
import Bottom from '@/components/bottom.vue'
export default {
  name: 'Home',
  components: { Nav, Bottom },
  data: function () {
    return {
      announceList: [], // 公告的类型
      announceTitle: '', // 公告的名称
    }
  },
  created() {
    this.reload()
  },
  beforeRouteUpdate(to, from, next) {
    if (to.fullPath != from.fullPath) {
      next()
      this.reload()
    }
  },
  methods: {
    reload() {
      var thiz = this
      console.log('78', this.$route.query.type)
      if (this.$route.query.type == 1) {
        thiz.announceTitle = '公告'
      } else if (this.$route.query.type == 2) {
        thiz.announceTitle = '通知'
      }
      RD.notice()
        .allByGroup(this.$route.query.type)
        .then((data) => {
          console.log('ad', data)
          data.forEach((k) => {
            k.imgurl = RD.notice().id(k.id).noticeImage_URL()
          })
          thiz.announceList = data
        })
    },
    // 详情
    onClick(id) {
      var type = 1
      if (this.$route.query.type == 2) {
        type = 2
      } else {
        type = 3
      }
      this.$router.push({
        path: '/announceDetail.html',
        query: { id: id, type: type },
      })
    },
  },
}
</script>

<style lang="less" scoped>
.all {
  height: auto;
}
.about {
  width: 1200px;
  margin: 0 auto;
  .announce-catitle {
    height: auto;
    .new-text {
      width: 50%;
      text-align: left;
      margin-left: 5px;
      font-size: 1.4rem;
      display: flex;
      padding-top: 10px;
      .title {
        width: 50px;
        height: 50px;
        background: no-repeat url(../assets/carame.png);
        background-size: cover;
      }
      .text {
        margin-left: 5px;
        font-size: 1.8rem;
        line-height: 50px;
      }
    }
    .line {
      width: 100%;
      height: 2px;
      margin-top: 10px;
      background-color: #bfbfbf;
    }
  }
  .announce-list {
    width: 1200px;
    margin: 20px auto;
    background: #f5f5f5;
    .announce-con {
      width: 1100px;
      margin: 20px auto;
      .annoce-title {
        height: 60px;
        line-height: 60px;
        text-align: left;
        font-size: 2rem;
        color: #d80c1d;
        padding-top: 20px;
        .title {
          display: inline-block;
          border-bottom: 1px solid #d80c1d;
        }
      }
      .annoce-nav {
        height: 220px;
        display: flex;
        margin: 30px 10px 10px 0px;
        padding-bottom: 30px;
        .img {
          width: 30%;
          height: 100%;
          background: no-repeat 50% / cover;
        }
        .text {
          height: 220px;
          margin-left: 20px;
          width: 68%;
          text-align: left;
          line-height: 40px;
          font-size: 1.4rem;
          .detail-text {
            height: 75%;
            overflow: hidden;
            text-overflow: -o-ellipsis-lastline;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            white-space: pre-wrap;
            text-indent: 20px;
          }
          .more,
          .time {
            color: #8b8b8b;
            text-align: right;
          }
          .more {
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
